.cardHeader {
  color: black;
  font-size: 35px;
  background-color: white;
}
.cardTitle {
  color: white;
  padding: 20px 0;
}
.cardBody {
  background-color: #061527;
  border: 1px solid white;
}
.cardText {
  text-align: left;
  color: gray;
  font-size: 20px;
}
.cardValue {
  text-align: right;
  color: #f5bc00;
}
.disabled {
  pointer-events: none;
  background-color: gray;
}
.buttonSee,
.buttonJoin {
  clip-path: polygon(0 0, 100% 0, 86% 100%, 15% 100%);
  width: auto;
  padding: 0 30px;
  font-size: 25px;
  color: black;
}
