.title {
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  font-weight: 800;
  margin-left: 10px;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-style: italic;
}

.navItem {
  color: rgba(255, 255, 255, 0.747);
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
}
.navItem::after {
  display: none;
}

.navItem:hover {
  color: rgb(206, 206, 206);
}
.navUnderline {
  border-bottom: 1px solid rgba(255, 255, 255, 0.747);
}

@media screen and (max-width: 310px) {
  .title {
    font-size: 20px;
    letter-spacing: 3px;
  }
}
