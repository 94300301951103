.card {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  width: 100%;
  background: url("https://i.ibb.co/0Qqf3pg/pattern-stable-chenar.png") center /
    cover no-repeat;
  background-color: #f5bc00;
  border-radius: 7px;
}

.cardContent {
  padding: 0 10px 20px 10px;
}

.raceName {
  font-size: 20px;
  font-weight: 800 !important;
  color: #f5bc00;
  background-color: #061527;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 7px 10px;
  border-radius: 0 0 7px 7px;
}

.raceDescription {
  font-size: 17px;
  font-weight: 500 !important;
  text-align: center;
  padding: 0 2px;
  margin: 12px 0;
}

.racePlayers {
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600 !important;
}
.racePlayersNB {
  font-size: 18px;
  font-weight: 600 !important;
  color: #061527;
  background: url("https://i.ibb.co/2SWvVD3/pattern-chenar-race.png") center /
    cover no-repeat;
  // background-color: #061527;
  border-radius: 7px;
  padding: 12px 10px;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.racePlayersNBSlash {
  color: black;
  padding: 0px 2.5px;
  font-size: 18px;
}

.racePrize {
  text-align: center;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600 !important;
  margin-top: 15px;
}

.racePrizeContent {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
}

.racePrizeItem {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500 !important;
}

.raceEnterBtn {
  font-size: 15px;
  color: #f5bc00;
  background: url("https://i.ibb.co/2SWvVD3/pattern-chenar-race.png") center /
    cover no-repeat;
  background-color: #061527;
  text-transform: uppercase;
  border: none;
  border-radius: 7px;
  padding: 4px 10px;
  display: block;
  margin: 10px auto;
}

.raceViewTitle {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 45px;
  font-weight: 700 !important;
  color: #f5bc00;
  margin-top: 15px;
  text-align: center;
}

.raceViewPlayers {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 700 !important;
  color: #061527;
  margin-top: 15px;
  text-align: center;
  background-color: #f5bc00;
  border-left: 6px solid #4198eb;
  border-right: 6px solid #4198eb;
  width: 230px;
  padding: 10px 10px;
  display: block;
  margin: 10px auto;
}

.raceViewParticipants {
  color: #f5bc00;
  font-size: 20px;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  margin-top: 20px;
  margin-bottom: 20px;

  span {
    color: #4198eb;
    font-weight: 700 !important;
  }
}

.raceViewCard {
  background: url("https://i.ibb.co/0Qqf3pg/pattern-stable-chenar.png") center /
    cover no-repeat;
  background-color: #f5bc00;
  margin: 10px 0px;
  border-radius: 7px;
}

.raceViewCardContent {
  padding: 7px 10px;
}

.raceViewCardContentImg {
  width: 100%;
  height: 380px;
  object-fit: cover;
}

.raceViewCardContentHorseName {
  color: #061527;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 700 !important;
  text-transform: uppercase;
  text-align: center;
}

.raceViewCardContentAddress {
  color: #061527;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500 !important;
  text-transform: uppercase;
  margin-top: 20px;
  text-align: center;

  small {
    text-transform: none;
  }
}
.comingSoon {
  font-size: 300px;
  display: flex;
  justify-content: center;
  color: #061527;
}
.joinColumn {
  position: relative;
}
.viewRace {
  position: absolute;
  right: 0;
  top: 16px;
  font-size: 15px;
  color: #061527;
}
.counter {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700 !important;
  color: #f5bc00;
  text-align: center;
  display: block;
  margin: 10px auto;
}

.raceTable {
  border: 1px solid #f5bc00;
  border-collapse: collapse;
  width: 100%;
  .header {
    display: flex;
    color: #fff;
    padding: 5px 15px 0 15px;
    text-transform: uppercase;
    border-bottom: 1px solid #f5bc00;
    .horses {
      margin-right: 10px;
      padding-right: 7px;
      border-right: 1px solid #f5bc006e;
    }
    div {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  tbody {
    .horse {
      padding: 15px;
      position: relative;
      display: flex;
      align-items: center;
      .position {
        color: #ffff;
        font-size: 20px;
        margin-right: 10px;
      }

      .horseIcon,
      .horseIconInRace {
        color: #fff;
        font-size: 60px;
      }

      .horseIconInRace {
        position: absolute;
      }
      .horseId {
        color: #061527;
        position: absolute;
        top: 34px;
        left: 52px;
        font-size: 15px;
        font-weight: 700 !important;
      }
    }
  }
}
.winnersTable {
  width: 100%;
  border: 1px solid #f5bc00;
  border-collapse: collapse;
  .winners {
    text-align: center;
    color: #f5bc00;
    padding: 2px 0;
    text-transform: uppercase;
  }
  .winnersPosition {
    height: 55px;
    border: 1px solid #f5bc00;
  }
}
@media only screen and (max-width: 425px) {
  .racePlayersNB {
    padding: 12px 0;
  }
  .viewRace {
    right: -15px;
  }
  .removePadding > * {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.wrong {
  font-size: 30px;
  color: #f5bc00;
}
.loading {
  position: relative;

  .bar {
    background-color: #a19c9c80;
    height: 14px;
    border-radius: 7px;
    width: 100%;
  }
  .race.bar {
    height: 500px;
  }
  &:after {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 24px;
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 80%
    );
    background-size: 200px 24px;
    background-position: -100px 0;
    background-repeat: no-repeat;
    animation: loading 1s infinite;
  }
}
@keyframes loading {
  40% {
    background-position: 100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.headers {
  font-size: 20px;
}
