.btn {
  width: 7.4rem;
  height: 2.3rem;
  outline: none;
  border: none;
  color: #fff;
  margin-right: 1rem;
  border-radius: 7px;
  background-color: black;
}
.custom-dialog {
  width: 90%;
}

.title {
  color: #fff;
  text-align: center;
}

.nftGrid {
  display: grid;
  width: 100%;
}

.nftContent {
  margin: 0 auto;
  text-align: center;
}

.img {
  margin: 0 auto;
  width: 300px;
  height: 300px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .nftGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .nftContent {
    margin: 0 auto;
    text-align: center;
    color: #fff;
  }

  .img {
    margin: 0 auto;
    width: 250px;
    height: 250px;
    cursor: pointer;
  }
}
