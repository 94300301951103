.title {
  color: #f5bc00;
  text-transform: uppercase;
  text-align: center;
  font-size: 40px;
  font-weight: 800 !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
.headers {
  font-size: 20px;
}
.contentTable {
  font-size: 17px;
}
.sort {
  margin-left: 15px;
}
.eye {
  color: #ffffff;
}
.tableFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25px;
}
.numberOfPage {
  font-size: 17px;
  font-weight: bold;
  margin: 0 24px;
}
.yourHorse {
  background-color: #f5bc00e2;
}
.button,
.buttons,
select {
  outline: transparent;
}
.buttons {
  width: 55px;
  margin: 0 5px;
}
.button {
  width: 30px;
}
.select {
  height: 25px;
  font-size: 17px;
}
@media screen and (max-width: 400px) {
  .numberOfPage {
    margin: 0 14px;
  }
  .buttons {
    width: 40px;
    margin: 0 2.5px;
  }
  .button {
    width: 30px;
  }
  .select {
    font-size: 14px;
  }
}
.loading {
  position: relative;

  .bar {
    background-color: #a19c9c80;
    height: 14px;
    border-radius: 7px;
    width: 100%;
  }

  &:after {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 24px;
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 80%
    );
    background-size: 200px 24px;
    background-position: -100px 0;
    background-repeat: no-repeat;
    animation: loading 1s infinite;
  }
}
.search {
  background-color: transparent;
  border: transparent;
  border-bottom: 1px solid white;
}
@keyframes loading {
  40% {
    background-position: 100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
