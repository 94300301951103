.disabled {
  pointer-events: none;
  background-color: gray;
}
.history {
  font-size: 45px;
  color: white;
  margin-bottom: 50px;
}
.loading {
  position: relative;

  .bar {
    background-color: #a19c9c80;
    height: 14px;
    border-radius: 7px;
    width: 100%;
  }
  .tournamentSkeleton.bar {
    height: 30px;
  }
  &:after {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 80%
    );
    background-size: 200px 24px;
    background-position: -100px 0;
    background-repeat: no-repeat;
    animation: loading 1s infinite;
  }
}
@keyframes loading {
  40% {
    background-position: 100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.noScrollBarr {
  -ms-overflow-style: none;
}
.noScrollBarr::-webkit-scrollbar {
  display: none;
}
