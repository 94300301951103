.logo {
  svg {
    width: 40px;
    height: 40px;
    margin-bottom: 7px;
  }
  margin-right: 10px;
}

.copy {
  font-size: 1.3rem;
}

.heart {
  color: red;
  padding: 0 6px 0 6px;
}

.message {
  font-size: 1.3rem;
  font-weight: 500 !important;
}

.icons {
  font-size: 1.3rem;
  width: 100%;
  height: 20px;
  color: #fff;
}
