.boxNft {
  background: url("https://i.ibb.co/nbqVdCp/pattern-chenar.png") center / cover
    no-repeat;
  background-color: #f5bc00;
  margin-bottom: 10px;
  border-radius: 7px;
  min-height: 353px;
}
.boxNftContent {
  font-family: "Montserrat", sans-serif;
  padding: 7px 5px;
  text-align: center;
}
.img {
  height: 280px;
  width: 140px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  margin-bottom: -30px;
}
.name {
  font-size: 20px;
  font-weight: 600 !important;
}
.stamina {
  font-size: 17px;
  font-weight: 500 !important;
  margin-top: -5px;
}
.btn {
  background-color: #061527;
  color: #f5bc00;
  font-size: 17px;
  font-weight: 600 !important;
  border-radius: 7px;
  border: none;
  padding: 5px 7px;
}
